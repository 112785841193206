import React from "react";

import Layout from "../components/Layout";
import PenIcon from "../components/PenIcon";
import DotIcon from "../components/DotIcon";

import ResearchSVG from "../assets/research.svg";
import ScienceSVG from "../assets/science.svg";
import SignalSVG from "../assets/signal.svg";

function Recherche() {
  return (
    <Layout title="Recherche">
      <h2 className="text-4xl font-bold leading-none text-center lg:text-6xl">
        KerNel for <span className="text-orange-500">Lungs</span>
      </h2>
      <section className="container w-screen mx-auto x-6">
        <div className="relative w-full p-4 mx-auto">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
            <div className="lg:col-start-2 md:pl-20">
              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <PenIcon />
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Nos thématiques
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>Recherche en santé respiratoire</li>
                        <li>Télémedecine</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <PenIcon />
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Nos activités
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>
                          Évaluation de dispositifs médicaux sur banc d'essai
                        </li>
                        <li>Traitement du signal</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <PenIcon />
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Pour qui ?
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>Chercheurs</li>
                        <li>Professionnels de santé et paramédicaux</li>
                        <li>Prestataires de santé</li>
                        <li>Fabricants de DMs</li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
              <ResearchSVG className="relative w-1/2 mx-auto" />
            </div>
          </div>
        </div>
      </section>
      <div className="py-6 bg-orange-100">
        <h2 className="my-4 text-4xl font-bold text-center">
          Études sur <span className="text-orange-500">banc d'essai</span>
        </h2>
        <section className="container px-6">
          <div className="relative p-4">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div className="lg:col-start-2 md:pl-20">
                <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:leading-9">
                  Nos missions
                </h4>
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Évaluation de dispositifs médicaux d'assistance
                          respiratoire
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Ventilateurs de domicile et de réanimation, masques,
                          humidificateurs, oxygène portable et haut débit,
                          drainage bronchique, etc.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Études comparatives
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Projets de recherche fondamentaux, benchmark pour
                          développement produit, analyses de performances pour
                          certification ou marquage CE
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Développement de protocoles spécifiques sur mesure
                        </h5>
                        <p className="mt-2 text-base leading-6 text-gray-500">
                          Analyse du besoin, état de l'art et définition du
                          spectre de l'étude, conseil aux phases préparatoires
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
                <ScienceSVG className="relative w-1/2 mx-auto" />
              </div>
            </div>
          </div>
        </section>
        <section className="p-6 mt-8 text-coolGray-800">
          <div className="container mx-auto">
            <span className="block text-xs font-medium tracking-widest text-center text-orange-600 uppercase">
              Intérêts
            </span>
            <div className="grid gap-6 mt-6 mb-16 lg:grid-cols-3">
              <div className="flex flex-col p-8 space-y-4 rounded-md bg-coolGray-50">
                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold bg-orange-600 rounded-full text-coolGray-50">
                  1
                </div>
                <p className="text-2xl font-semibold">
                  Expertise en santé respiratoire
                </p>
              </div>
              <div className="flex flex-col p-8 space-y-4 rounded-md bg-coolGray-50">
                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold bg-orange-600 rounded-full text-coolGray-50">
                  2
                </div>
                <p className="text-2xl font-semibold">
                  Laboratoire indépendant
                </p>
              </div>
              <div className="flex flex-col p-8 space-y-4 rounded-md bg-coolGray-50">
                <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold bg-orange-600 rounded-full text-coolGray-50">
                  3
                </div>
                <p className="text-2xl font-semibold">
                  Données objectives pour mise sur le marché, développement ou
                  éléments marketing
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="pb-8">
        <h2 className="my-8 text-4xl font-bold text-center lg:text-5xl">
          Traitement du signal{" "}
          <span className="text-orange-500">ventilatoire</span>
        </h2>
        <div className="container px-6">
          <div className="relative p-4">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div className="lg:col-start-1 md:pl-20">
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Nos activités
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">
                            Analyse de données issues de simulations et de la
                            vie réelle
                          </li>
                          <li className="my-1">
                            Développement d'algorithmes et logiciels d'analyse
                            sur mesure
                          </li>
                          <li className="my-1">
                            Modélisation de systèmes biologiques
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Thématiques
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">
                            Télésuivi (patients ventilés, sous PPC)
                          </li>
                          <li className="my-1">
                            Asynchronismes patient-ventilateur
                          </li>
                          <li className="my-1">
                            Interactions masques-patients
                          </li>
                          <li className="my-1">
                            Télémédecine (dispositifs connectés)
                          </li>
                          <li className="my-1">Trachéobronchomalacie</li>
                          <li className="my-1">Qualité de vie des patients</li>
                          <li className="my-1">Réhabilitation respiratoire</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-2">
                <SignalSVG className="relative w-1/2 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Recherche;
